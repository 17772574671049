import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { TextField, Autocomplete, Table } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { clientListGuide } from "./guide";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },

  {
    id: "companyName",
    label: "Company Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "address",
    label: "Address",
    align: "left",
    minWidth: 40,
  },
  {
    id: "branch",
    label: "Branch",
    align: "left",
    minWidth: 40,
  },
  {
    id: "gstNumber",
    label: "GST Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "state",
    label: "State",
    align: "left",
    minWidth: 40,
  },
  {
    id: "email",
    label: "Email",
    align: "left",
    minWidth: 40,
  },
  // {
  //   id: "status",
  //   label: "Status",
  //   align: "left",
  //   minWidth: 40,
  // },
];

function createData(
  id,
  companyName,
  // contact,
  address,
  branch,
  gstNumber,
  state,
  email,
  status
) {
  return {
    id,
    companyName,
    // contact,
    address,
    branch,
    gstNumber,
    state,
    email,
    status,
  };
}

export default function ClientsList() {
  const clientListGuides = clientListGuide();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [clientList, setClientsList] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [rowDaata, setRowData] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axios
      .patch(url + `clients/${id}`, jsonData)
      .then((res) => {
        toast("client was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Company Name": "companyName",
        "Address": "address",
        "Branch": "branchName",
        "State": "state",
      }[searchBy];
      whereConditions = `and ${colName} like '%${searchKey}%'`;
    }
 

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` lastModified between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `select c.id as id, c.companyName as companyName, c.address as address, b.branchName as branch, c.gstNumber as gstNumber, c.state as state, c.email as email, c.status as status from clients as c join branch as b on c.branch = b.id where c.status = 1 and b.status = 1 ${whereConditions} order by c.id DESC ${pagination_settings}`,
    };
    if (count)
      data.query = `select count(c.id) no_of_rows from clients as c join branch as b on c.branch = b.id where c.status = 1 and b.status = 1 ${whereConditions} order by c.id DESC ${pagination_settings}`;
    return data;
  };

  function getClientList() {
    let data = getQuery();


    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setClientsList([...res.data]);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  // api calls
  function getTotalRows() {
    let data = getQuery(true);
    axios
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };
  const search = () => {
    getClientList();
  };

  useEffect(() => {
    getTotalRows();
  }, []);

  useEffect(() => {
    getClientList();
  }, [rowsPerPage, page]);

  const generateRowData = (clientList) => {
    const newRowData = clientList.map((client) => {
      return createData(
        client.id,
        client.companyName,
        client.address,
        client.branch,
        client.gstNumber,
        client.state,
        client.email,
        client.status
      );
    });

    setRowData(newRowData);
  };

  useEffect(() => {
    generateRowData(clientList);
  }, [clientList]); 

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <div style={{display:"flex",justifyContent:"space-between"}}>            
      <Toolbar>
        <Button
        id="client_list_createbtn"
          variant="contained"
          size="small"
          component={Link}
          to="/master/client/createClient"
        >
          <b>CREATE CLIENT</b>
        </Button>
      </Toolbar>
      <Tooltip title="User Guide" placement="top-start">
        <Button
         onClick={() => {
          clientListGuides.drive();
         }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
    </div>  
      <br />
      <div
        className="mb-2"
        style={{
          display: "flex",
          float: "left",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
      <div
        className="mb-2"
        style={{ display: "flex", float: "left", justifyContent: "flex-end", marginRight: "10px" }}
      >
        <br />

        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px",
          }}
          size="small"
          id="client_list_searchby"
          options={[
            { key: "companyName", label: "Company Name" },
            { key: "address", label: "Address" },
            { key: "branch", label: "Branch" },
            { key: "state", label: "State" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
        <TextField
          id="client_list_searchbytext"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
          sx={{marginLeft:"10px"}}
        />
        <Button
        id="client_list_searchbtn"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            search();
          }}
        >
          <SearchIcon />
        </Button>
      </div>
      </div>
      <br />

      <Table stickyHeader aria-label="sticky table" size="small" id="client_list_table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowDaata
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    // let value = row[column.id];
                    let value=row[column.id];
                    // if (column.id === "createdBy") {
                    //   let userObject=users.filter((e)=>e.id ===  row[column.id] )
                    //   value = row[column.id] ? userObject[0]?.userName : null;
                    // } else {
                    //   value = row[column.id];
                    // }
                    if (column.id == "id") {
                      value = page * rowsPerPage + index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}

<TableCell>
                  <ButtonGroup size="small" aria-label="small button group">
                    <Tooltip title="Edit Client" placement="top-start">
                      <Button
                      id="client_list_edit"
                        component={Link}
                        to={`/master/editClient/${row.id}`}
                      >
                        <PreviewIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Delete Client" placement="top-start">
                      <Button
                      id="client_list_delete"
                        onClick={(e) => {
                          if (window.confirm("Really want to delete client?")) {
                            submitSiteDelete(row.id);
                          }
                        }}
                      >
                        <DeleteIcon style={{ color: "#dc3545" }} />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
      />
      <ToastContainer />
    </TableContainer>
  );
}
