// Use This URL for testing
const URL = "kltechnolgoy.bfiinfotech.co.in";

export const BASE_URL = `https://${URL}/api/`;
export const DOMAIN = `https://${URL}`;
export const MAX_COLS = 30;
export const ID_BASE = 1;
export const SRF_ID_BASE = 1;

// TODO: need to move to DB
export const BANK_DETAILS = {
  "Bank Name": "State Bank OF India",
  "Bank A/c. No.": "35550542811",
  "IFSC Code": "SBIN0009950",
};
export const GST_NUMBER = "06BRUPM4712K1ZB";

export const DISABLED_MODULE =["Summary Report","Courier Register","History Card","Expenses","Master In/Out","Customer Complaints","Customer Feedback","Audit","Document Upload","User","Purchase"
,"DUC Master","Department"]; 
